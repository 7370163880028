import React from 'react'

// App components
import ContactInformation from '../../components/ContactInformation'
import Layout from '../../components/layout'
import Map from '../../components/Map'
import PageTitle from '../../components/PageTitle'

// App utils
import { themeStyles } from '../../utils/theme'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="Map and Directions"
            bannerKey={'MAP_AND_DIRECTIONS'}
            backgroundPosition="30% 50%"
            minHeight="360px"
          />
        </div>
        <ContactInformation />
        <Map />
      </Layout>
    )
  }
}

export default Page

import React from 'react'
import { rhythm } from '../utils/typography'
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa'
import { colors } from '../utils/theme'
import { parent } from 'glamor'
import CustomLink from '../components/CustomLink'

const styles = {
  iconWrapper: {
    backgroundColor: colors.brightBlue,
    height: '80px',
    width: '80px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  elementWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    textAlign: 'center',
    alignItems: 'center',
  },
  link: {
    cursor: 'pointer',
  },
}

const MAPS_URL =
  'https://www.google.com/maps/dir/?api=1&destination_place_id=ChIJjYuFOzpf94cRcYSVA1lWhI4&destination=Collins+Orthodontics'

class ContactInformation extends React.Component {
  render() {
    const parentBasedBrightness = parent('a:hover >', {
      filter: 'brightness(85%)',
    })
    const parentBasedColor = parent('a:hover >', { color: colors.brightBlue })
    return (
      <div
        css={{
          display: 'flex',
          width: '100%',
          maxWidth: '1000px',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          margin: '0 auto',
          paddingTop: rhythm(4),
          paddingBottom: rhythm(2),
        }}
      >
        <CustomLink href={MAPS_URL} css={[styles.elementWrapper, styles.link]}>
          <div css={[styles.iconWrapper, parentBasedBrightness]}>
            <FaMapMarkerAlt color="white" size={40} />
          </div>
          <p css={parentBasedColor}>2946 Jeremiah Ln NW, Rochester, MN 55901</p>
        </CustomLink>
        <CustomLink
          href="tel:15072585400"
          css={[styles.elementWrapper, styles.link]}
        >
          <div css={[styles.iconWrapper, parentBasedBrightness]}>
            <FaPhone color="white" size={40} />
          </div>
          <p css={parentBasedColor}>(507) 258-5400</p>
        </CustomLink>
        <CustomLink
          href="mailto:info@rochesterorthodontics.com"
          css={[styles.elementWrapper, styles.link]}
        >
          <div css={[styles.iconWrapper, parentBasedBrightness]}>
            <FaEnvelope color="white" size={40} />
          </div>
          <p css={parentBasedColor}>info@rochesterorthodontics.com</p>
        </CustomLink>

        <div css={styles.elementWrapper}>
          <div css={styles.iconWrapper}>
            <FaClock color="white" size={40} />
          </div>
          <p>
            Mon: 8am - 5pm
            <br />
            Tues: 8am - 5pm
            <br />
            Wed: 10am - 7pm
            <br />
            Thurs: 8am - 5pm
            <br />
            Fri: 7am - 4pm
            <br />
            Sat & Sun: Closed
            <br />
          </p>
        </div>
      </div>
    )
  }
}

export default ContactInformation

import React from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'

const Map = (props) => {
  return (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.0439642, lng: -92.5097772 }}
    >
      <Marker position={{ lat: 44.0439642, lng: -92.5097772 }} />
    </GoogleMap>
  )
}

const WrappedMap = withScriptjs(withGoogleMap(Map))

const CollinsMap = () => (
  <WrappedMap
    isMarkerShown
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBJnHq_oSWAdrJQx50NL50m8ZXSp8to3Ek&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div css={{ height: `100%` }} />}
    containerElement={<div css={{ height: `500px` }} />}
    mapElement={<div css={{ height: `100%` }} />}
  />
)

export default CollinsMap
